<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Barrel filling details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-select
                :id="controls.alcohol_finishing.id"
                :value="data.alcohol_finishing"
                :label="controls.alcohol_finishing.label"
                :readonly="controls.alcohol_finishing.readonly"
                :options="controls.alcohol_finishing.options"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                :link-mode="true"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.created.id"
                :value="data.created"
                :label="controls.created.label"
                :readonly="true"
              />
            </b-col>
            <b-col lg="3" md="6" sm="6">
              <inline-input
                :id="controls.created_by.id"
                :value="data.created_by"
                :label="controls.created_by.label"
                :readonly="true"
              />
            </b-col>
          </b-row>

          <b-row v-if="data.amount_remaining">
            <b-col cols="3">
              <font-awesome-icon
                v-if="!newItemTemplateVisible"
                icon="chevron-down"
              />
              <font-awesome-icon
                v-if="newItemTemplateVisible"
                icon="chevron-up"
              />
              <h5
                v-b-toggle.collapse-1
                style="display: inline; cursor: pointer"
              >
                New item template
              </h5>
            </b-col>
          </b-row>

          <b-collapse
            v-if="data.amount_remaining"
            id="collapse-1"
            class="mt-2"
            v-model="newItemTemplateVisible"
          >
            <b-row>
              <b-col lg="1" md="6" sm="6">
                <inline-switch
                  :id="controls.batch_barreling.id"
                  :value="data.batch_barreling.enabled"
                  :readonly="controls.batch_barreling.readonly"
                  :label="controls.batch_barreling.label"
                  @changed="onBatchBarrelingChange"
                />
              </b-col>
              <b-col lg="2" md="6" sm="6" v-if="data.batch_barreling.enabled">
                <inline-input
                  :id="controls.barrels_amount.id"
                  :value="data.batch_barreling.barrels_amount"
                  :label="controls.barrels_amount.label"
                  :readonly="controls.barrels_amount.readonly"
                  :mode="$constants.FORM_MODE.EDIT"
                  :required="controls.barrels_amount.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="6">
                <inline-select
                  :id="controls.lot_number.id"
                  :value="data.lot_number"
                  :label="controls.lot_number.label"
                  :readonly="controls.lot_number.readonly"
                  :options="controls.lot_number.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="$constants.FORM_MODE.EDIT"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="2" md="6" sm="6">
                <inline-input
                  :id="controls.barrel_inside.id"
                  :value="data.barrel_inside_text"
                  :label="controls.barrel_inside.label"
                  :readonly="controls.barrel_inside.readonly"
                  :mode="mode"
                  :required="controls.barrel_inside.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="2" md="6" sm="6">
                <inline-input
                  :id="controls.barrel_size.id"
                  :value="data.barrel_size"
                  :label="controls.barrel_size.label"
                  :readonly="controls.barrel_size.readonly"
                  :mode="mode"
                  :required="controls.barrel_size.required"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="2" md="6" sm="6">
                <inline-input
                  :id="controls.barrel_description.id"
                  :value="data.barrel_description"
                  :label="controls.barrel_description.label"
                  :readonly="controls.barrel_description.readonly"
                  :mode="mode"
                  :required="controls.barrel_description.required"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.barrel_date.id"
                  :value-single="data.barrel_date"
                  :label="controls.barrel_date.label"
                  :readonly="controls.barrel_date.readonly"
                  :mode="$constants.FORM_MODE.EDIT"
                  :required="controls.barrel_date.required"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.amount_used.id"
                  :value="data.amount_used"
                  :label="
                    data.batch_barreling.enabled
                      ? 'Average amount per barrel (liters)'
                      : controls.amount_used.label
                  "
                  :readonly="controls.amount_used.readonly"
                  :mode="$constants.FORM_MODE.EDIT"
                  :required="controls.amount_used.required"
                  @changed="updateField"
                />
                <b-alert
                  v-if="controls.amountUsedAlert.visible"
                  :ref="controls.amountUsedAlert.id"
                  show
                  :variant="controls.amountUsedAlert.color"
                  >{{ controls.amountUsedAlert.text }}</b-alert
                >
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.alcohol_percentage.id"
                  :value="data.alcohol_percentage"
                  :label="controls.alcohol_percentage.label"
                  :readonly="controls.alcohol_percentage.readonly"
                  :mode="$constants.FORM_MODE.EDIT"
                  :required="controls.alcohol_percentage.required"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.warehouse.id"
                  :value="data.warehouse"
                  :label="controls.warehouse.label"
                  :readonly="controls.warehouse.readonly"
                  :options="controls.warehouse.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="$constants.FORM_MODE.EDIT"
                  @changed="updateSelect"
                  :required="controls.warehouse.required"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6"> </b-col>
            </b-row>
          </b-collapse>
        </form>
        <hr />
        <barrel-filling-items-table
          ref="barrel-filling-items"
          :action-id="id"
          :show-add-button="showAddButton"
          :form-data="data"
          @add-item="onBarrelItemAdd"
        ></barrel-filling-items-table>

        <hr />
        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey(dataset, id),
            next: $customTable.getNextKey(dataset, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey(dataset, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey(dataset, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Barrel filling' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Barrel filling submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Barrel filling submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import FormSubmissionActions from "@/components/FormSubmissionActions";
import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
import InlineSwitch from "@/components/InlineSwitch";
import BarrelFillingItemsTable from "./BarrelFillingItemsTable";

import { mapState, mapActions } from "vuex";

export default {
  name: "BarrelFillingSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    FormSubmissionActions,
    InlineSwitch,
    BarrelFillingItemsTable
  },
  data: function() {
    return {
      newItemTemplateVisible: true,
      showAddButton: false,
      dataset: {
        name: "barrel-filling"
      },
      baseUrl: "barrel-filling",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        alcohol_finishing: {
          id: "select:alcohol_finishing",
          label: "Alcohol finishing",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        barrel_type: {
          id: "select:barrel_type",
          label: "Barrel Type",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        lot_number: {
          id: "select:lot_number",
          label: "Lot number",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: true
        },
        batch_barreling: {
          id: "switch:batch_barreling",
          label: "Batch barreling",
          value: false,
          changed: false,
          readonly: false
        },
        barrels_amount: {
          id: "input:barrels_amount",
          label: "Barrels amount",
          changed: false,
          readonly: false,
          required: false
        },

        barrel_inside: {
          id: "input:barrel_inside",
          label: "Barrel inside",
          changed: false,
          readonly: true,
          required: true
        },
        barrel_size: {
          id: "input:barrel_size",
          label: "Barrel gallons",
          changed: false,
          readonly: true,
          required: true
        },
        barrel_description: {
          id: "input:barrel_description",
          label: "Barrel desription",
          changed: false,
          readonly: true,
          required: true
        },
        amount_used: {
          id: "input:amount_used",
          label: "Amount Used (liters)",
          changed: false,
          readonly: false,
          required: false
        },
        alcohol_percentage: {
          id: "input:alcohol_percentage",
          label: "Alcohol %",
          changed: false,
          readonly: false,
          required: false
        },
        barrel_date: {
          id: "datepicker:barrel_date",
          label: "Date barreled",
          changed: false,
          readonly: false,
          required: false
        },
        warehouse: {
          id: "select:warehouse",
          label: "Warehouse Location",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: false
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        },
        created_by: {
          id: "input:created_by",
          label: "Created By",
          changed: false,
          readonly: true
        },
        modified_by: {
          id: "input:modified_by",
          label: "Modified By",
          changed: false,
          readonly: true
        },
        amountUsedAlert: {
          id: "amount-used-alert",
          visible: false,
          text: "alert text"
        }
      },
      data: {
        id: "",
        batch_barreling: {
          enabled: false,
          barrels_amount: 1,
          avg_per_barrel: 0
        },
        alcohol_finishing: "",
        barrel_type: "",
        barrel_inside: "",
        barrel_size: "",
        barrel_description: "",
        lot_number: "",
        amount_remaining: 0,
        amount_used: "",
        alcohol_percentage: "",
        barrel_date: "",
        warehouse: "",
        created: "",
        modified: "",
        created_by: "",
        modified_by: ""
      },

      datasetKeys: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      await this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);

        this.controls.alcohol_finishing.readonly = true;

        //this.controls.alcohol_percentage.readonly = true;
        //this.controls.lot_number.readonly = true;
        //this.controls.amount_used.readonly = true;
        //this.controls.batch_barreling.readonly = true;
        //this.controls.warehouse.readonly = true;
      }
    },

    async loadDictionaries() {
      let self = this;

      const alcohol_finishing = async () => {
        let response = await this.$api.post(
          "alcohol-finishing/barrel-finishings"
        );

        self.controls.alcohol_finishing.options = response.map(u => ({
          id: u.finished_product_number,
          label: u["Item Name"],
          action_number: u.action_number,
          amount_remaining: u.remaining_amount,
          alcohol_percentage: u.wanted_product_percentage,
          finished_product_id: u.finished_product_id,
          finished_product_number: u.finished_product_number,
          barrel_number: u.barrel_number
        }));
      };

      const lot_numbers = async () => {
        let response = await this.$api.get("inventory/available-barrel-lots");

        self.controls.lot_number.options = response.map(u => ({
          id: u.id,
          item_order_number: u.item_order_number,
          label: u.lot_name,
          wood_type: u.wood_type,
          inside: u.inside,
          inside_text: u.inside_text,
          heads: u.heads,
          heads_text: u.heads_text,
          size: u.size,
          seasoning: u.seasoning
        }));
      };

      const warehouses = async () => {
        let response = await this.$api.get("dictionaries/warehouses");

        self.controls.warehouse.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      await Promise.all([alcohol_finishing(), warehouses(), lot_numbers()]);

      this.setDefaults();
    },
    setDefaults() {
      let self = this;
      if (self.mode === this.$constants.FORM_MODE.CREATE) {
        self.data.barrel_date = self.$nowClient.format("YYYY-MM-DD");
      }
    },
    save(_mode) {
      if (!this.$refs["barrel-filling-items"].getDataSet().length) {
        this.$form.makeToastError("You have to add at least one barrel");

        return Promise.resolve(false);
      }
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let url = this.baseUrl,
        method = "put";

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        url = `${this.baseUrl}/${this.data.id}`;
      }

      this.data.items = this.$refs["barrel-filling-items"].getDataSet();

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value) {
      //this.$form.updateField(this.baseUrl, this, field, value, mode);

      let self = this;
      let params = {};
      self.data[field] = value;
      params["id"] = self.id;
      params[field] = value;

      if (field === "barrels_amount") this.updateAveragePerBarrel();

      if (field === "amount_used") {
        this.validateUsedAmount(value);
      }
    },
    validateUsedAmount(value) {
      if (!this.data.lot_number) return;

      let deltaPercents =
        ((value - this.data.barrel_size * 3.7854118) * 100) /
        (this.data.barrel_size * 3.7854118);

      if (deltaPercents > 10 && deltaPercents < 20) {
        this.controls.amountUsedAlert.text = `Selected amount exeeds the volume of the barrel by ${deltaPercents.toFixed(
          2
        )}%`;
        this.controls.amountUsedAlert.color = "warning";
        this.controls.amountUsedAlert.visible = true;
      } else if (deltaPercents >= 20) {
        this.controls.amountUsedAlert.text = `Selected amount exeeds the volume of the barrel by ${deltaPercents.toFixed(
          2
        )}%`;
        this.controls.amountUsedAlert.color = "danger";
        this.controls.amountUsedAlert.visible = true;
      } else this.controls.amountUsedAlert.visible = false;
    },
    updateAveragePerBarrel() {
      if (this.data.batch_barreling.enabled && +this.data.barrels_amount > 0) {
        this.data.amount_used = this.$helpers.round(
          this.data.amount_remaining / this.data.barrels_amount,
          2
        );
      }
    },
    updateSelect(id, value) {
      if (id === "lot_number") {
        this.data.lot_number = value;

        this.data.barrel_inside = value.inside;
        this.data.barrel_inside_text = value.inside_text;
        this.data.barrel_size = value.size;
        this.data.barrel_description = `${value.wood_type} ${value.heads_text} Heads ${value.seasoning} mo`;

        this.validateUsedAmount(this.data.amount_used);
      }
      if (id === "alcohol_finishing") {
        this.data.alcohol_finishing = value;
      }

      if (id === "warehouse") {
        this.data.warehouse = value;
      }
    },

    async fetchData(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`${this.baseUrl}/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.alcohol_finishing = {
            id: response["Finished Product Number"],
            label: response["AF Name"],
            url: `${this.$client_url}/#/production/alcohol-finishing/view/${this.id}`
          };

          self.data.barrel_number = response["Barrel #"];
          self.data.barrel_date = response["Barrel Date"];
          self.data.amount_used = response["Amount Used"];
          self.data.alcohol_percentage = response["Alcohol %"];
          self.data.lot_number = {
            id: response["Lot ID"],
            label: response["Lot Name"]
          };
          self.data.barrel_size = response["Barrel Size"];
          self.data.barrel_inside_text = response["Inside Text"];
          self.data.barrel_description = response["Description"];

          self.data.warehouse = {
            id: response["Warehouse ID"],
            label: response["Warehouse"]
          };

          self.data.created = response["Created"];
          self.data.modified = response["Modified"];
          self.data.created_by = response["Created By"];
          self.data.modified_by = response["Modified By"];
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    },
    onBatchBarrelingChange(id, value) {
      this.data.batch_barreling.enabled = value;
    },
    onBarrelItemAdd() {
      console.log("onBarrelItemAdd");
    }
  },
  watch: {
    "data.alcohol_finishing"(newValue) {
      let af = this.controls.alcohol_finishing.options.find(
        i => i.finished_product_number === newValue.id
      );
      console.log("watch.af:", newValue, af);

      if (af && af.alcohol_percentage) {
        this.data.alcohol_finishing = af;
        this.data.alcohol_percentage = af.alcohol_percentage;
        this.data.amount_used = af.amount_remaining;
        this.data.amount_remaining = af.amount_remaining;

        this.updateAveragePerBarrel();
      }

      //if (newValue && newValue.id && this.data.lot_number.id)

      if (newValue && newValue.id && af && af.amount_remaining > 0)
        this.showAddButton = true;
      else this.showAddButton = false;
    },
    "data.amount_used"(newValue) {
      this.validateUsedAmount(newValue);
    },
    "data.lot_number"() {
      this.validateUsedAmount(this.data.amount_used);

      /*
      if (newValue.id && this.data.alcohol_finishing.id)
        this.showAddButton = true;
      else this.showAddButton = false;
      */
    }
  }
};
</script>

<style></style>
