var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Barrel filling details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.alcohol_finishing.id,
      "value": _vm.data.alcohol_finishing,
      "label": _vm.controls.alcohol_finishing.label,
      "readonly": _vm.controls.alcohol_finishing.readonly,
      "options": _vm.controls.alcohol_finishing.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created_by.id,
      "value": _vm.data.created_by,
      "label": _vm.controls.created_by.label,
      "readonly": true
    }
  })], 1)], 1), _vm.data.amount_remaining ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [!_vm.newItemTemplateVisible ? _c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }) : _vm._e(), _vm.newItemTemplateVisible ? _c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-up"
    }
  }) : _vm._e(), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-1",
      modifiers: {
        "collapse-1": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" New item template ")])], 1)], 1) : _vm._e(), _vm.data.amount_remaining ? _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-1"
    },
    model: {
      value: _vm.newItemTemplateVisible,
      callback: function callback($$v) {
        _vm.newItemTemplateVisible = $$v;
      },
      expression: "newItemTemplateVisible"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "1",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.batch_barreling.id,
      "value": _vm.data.batch_barreling.enabled,
      "readonly": _vm.controls.batch_barreling.readonly,
      "label": _vm.controls.batch_barreling.label
    },
    on: {
      "changed": _vm.onBatchBarrelingChange
    }
  })], 1), _vm.data.batch_barreling.enabled ? _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrels_amount.id,
      "value": _vm.data.batch_barreling.barrels_amount,
      "label": _vm.controls.barrels_amount.label,
      "readonly": _vm.controls.barrels_amount.readonly,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.barrels_amount.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.lot_number.id,
      "value": _vm.data.lot_number,
      "label": _vm.controls.lot_number.label,
      "readonly": _vm.controls.lot_number.readonly,
      "options": _vm.controls.lot_number.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_inside.id,
      "value": _vm.data.barrel_inside_text,
      "label": _vm.controls.barrel_inside.label,
      "readonly": _vm.controls.barrel_inside.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.barrel_inside.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_size.id,
      "value": _vm.data.barrel_size,
      "label": _vm.controls.barrel_size.label,
      "readonly": _vm.controls.barrel_size.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.barrel_size.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_description.id,
      "value": _vm.data.barrel_description,
      "label": _vm.controls.barrel_description.label,
      "readonly": _vm.controls.barrel_description.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.barrel_description.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.barrel_date.id,
      "value-single": _vm.data.barrel_date,
      "label": _vm.controls.barrel_date.label,
      "readonly": _vm.controls.barrel_date.readonly,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.barrel_date.required
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_used.id,
      "value": _vm.data.amount_used,
      "label": _vm.data.batch_barreling.enabled ? 'Average amount per barrel (liters)' : _vm.controls.amount_used.label,
      "readonly": _vm.controls.amount_used.readonly,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.amount_used.required
    },
    on: {
      "changed": _vm.updateField
    }
  }), _vm.controls.amountUsedAlert.visible ? _c('b-alert', {
    ref: _vm.controls.amountUsedAlert.id,
    attrs: {
      "show": "",
      "variant": _vm.controls.amountUsedAlert.color
    }
  }, [_vm._v(_vm._s(_vm.controls.amountUsedAlert.text))]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.alcohol_percentage.id,
      "value": _vm.data.alcohol_percentage,
      "label": _vm.controls.alcohol_percentage.label,
      "readonly": _vm.controls.alcohol_percentage.readonly,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.alcohol_percentage.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.warehouse.id,
      "value": _vm.data.warehouse,
      "label": _vm.controls.warehouse.label,
      "readonly": _vm.controls.warehouse.readonly,
      "options": _vm.controls.warehouse.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.warehouse.required
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  })], 1)], 1) : _vm._e()], 1), _c('hr'), _c('barrel-filling-items-table', {
    ref: "barrel-filling-items",
    attrs: {
      "action-id": _vm.id,
      "show-add-button": _vm.showAddButton,
      "form-data": _vm.data
    },
    on: {
      "add-item": _vm.onBarrelItemAdd
    }
  }), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Barrel filling'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Barrel filling submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Barrel filling submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }