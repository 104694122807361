<template>
  <div class="animated fadeIn">
    <b-row v-if="this.showAddButton">
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            size="sm"
            :disabled="$refs.dataTable.isInsertingMode"
            @click="addItem()"
            title="Add item"
          >
            <font-awesome-icon icon="plus" /> Add item
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <table-custom
      ref="dataTable"
      name="ct_barrel_filling_items"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
    >
      <div slot="Barrel #" slot-scope="props">
        <b-link
          :href="
            `${$client_url}/#/production/barrel/view/${props.row['Barrel #']}`
          "
        >
          {{ props.row["Barrel #"] }}
        </b-link>
      </div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group" v-if="!props.row['Created']">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";

import TableCustom from "@/components/TableCustom";

import { FORM_MODE } from "@/shared/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "BarrelFillingItemsTable",
  props: {
    actionId: {
      type: [String, Number],
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    formData: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    }
  },
  components: {
    TableCustom
  },
  data: function() {
    return {
      maxBarrelNumber: 0,
      isLoading: false,

      selectAll: false,
      selectedRows: [],
      dropdowns: {
        inventoryItem: [],
        lotNumber: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          readOnly: true,
          slots: ["Barrel #"],
          columns: [
            "ID",
            "Barrel #",
            "Barrel Date",
            "Barrel Status",
            "Amount Used",
            "Warehouse",
            "Notes",
            "Barrel Inside",
            "Barrel Size",
            "Alcohol %",
            "Description",
            "Actions"
          ],
          editableColumns: [
            "Barrel #",
            "Barrel Date",
            "Barrel Status",
            "Amount Used",
            "Warehouse",
            "Notes"
          ],
          maskedColumns: [],
          dropdownColumns: [
            { name: "Barrel #", options: [] },
            { name: "Warehouse", options: [] },
            { name: "Barrel Status", options: [] }
          ],
          perPage: 50,
          showCustomActions: true,
          //showActions: true,
          showChildRows: false,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      },

      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.actionId) {
        this.getData(this.actionId);
      }
    },

    onInlineDropdownChange(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Warehouse") {
        row["warehouse_id"] = e.value.id;
      }
      if (e.column === "Barrel Status") {
        row["barrel_status_id"] = e.value.id;
      }
      if (e.column === "Barrel #") {
        row["Barrel #"] = e.value.id;
      }

      /*
      if (e.column === "Lot Number") {
        row["item_order_number"] = e.value.id;
      }
*/
      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate() {
      /*
      let rowIndex = this.dataTable.dataSet.findIndex(i => i["ID"] === e.id);
      let row = this.dataTable.dataSet[rowIndex];

      row[e.name] = e.value;

      Vue.set(this.dataTable.dataSet, rowIndex, row);
      */
    },
    onInlineUpdate(e) {
      console.log("onInlineUpdate", JSON.stringify(e));

      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }

      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;

      /*
      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put(`mash/item/${row["ID"]}`, row)
          .then(response => {
            this.$form.makeToastInfo(response.message);

            this.getData();
          })
          .catch(function(error) {
            this.$form.makeToastError(error.message);
            this.getData();
          });
      }
      */
    },
    async topOffBarrelsLoad() {
      let response = await this.$api.get("barrels/top-off-barrels");

      this.dataTable.options.dropdownColumns.find(
        c => c.name === "Barrel #"
      ).options = response.map(u => ({
        id: u.barrel_number,
        label: `${u.barrel_number} REM: ${u.amount}`
      }));
    },
    loadDictionaries() {
      let self = this;

      const warehouses = () => {
        self.$api.get("dictionaries/warehouses").then(response => {
          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Warehouse"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      const barrels_statuses = () => {
        self.$api.get("dictionaries/barrels/statuses").then(response => {
          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Barrel Status"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([warehouses(), barrels_statuses(), this.topOffBarrelsLoad()]);
    },

    getDataSet() {
      return this.dataTable.dataSet;
    },
    async addItem() {
      this.maxBarrelNumber = await this.$api.get("barrels/max-number");
      await this.topOffBarrelsLoad();

      let k = 1;
      if (
        this.formData.batch_barreling.enabled &&
        this.formData.barrels_amount > 0
      )
        k = this.formData.barrels_amount;

      for (let i = 0; i < k; i++) {
        this.maxBarrelNumber++;

        let newItem = {
          // uid: this.$helpers.uuidv4(),
          ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          "Barrel #": this.maxBarrelNumber,
          "Barrel Date": this.formData.barrel_date,
          barrel_type: this.formData.barrel_inside,
          barrel_status_id: 1,
          "Barrel Status": "Full",
          "Barrel Inside": this.formData.barrel_inside_text,
          "Barrel Size": this.formData.barrel_size,
          "Amount Used": this.formData.amount_used,
          "Alcohol %": this.formData.alcohol_percentage,
          Description: this.formData.barrel_description,
          warehouse_id: this.formData.warehouse.id,
          Warehouse: this.formData.warehouse.label,
          Notes: "",
          finished_product_id: this.formData.alcohol_finishing
            .finished_product_id,
          finished_product_number: this.formData.alcohol_finishing.id,
          item_order_number: this.formData.lot_number.item_order_number
        };

        this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);
      }

      // this.dataTable.isInserting = true;

      this.$emit("add-item");
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;

      let totalAmount = this.dataTable.dataSet
        .map(item => +item["Amount Used"])
        .reduce((a, b) => a + b);

      if (!newRecord["Amount Used"]) {
        self.$form.makeToastError("Please enter amount used");
        return;
      }

      if (+totalAmount > +this.formData.amount_remaining) {
        self.$form.makeToastError(
          `Total amount can't be greater then ${this.formData.amount_remaining}L`
        );
        return;
      }

      if (!newRecord["warehouse_id"] || newRecord["warehouse_id"] === 0) {
        self.$form.makeToastError("Please select a warehouse");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      /*
SINGLE
      "$barrel_number_manual",
*/

      newRecord.ID = newRecord.uid;

      data["Barrel #"] = newRecord["Barrel #"];
      data["barrel_type"] = newRecord["barrel_type"];
      data["Barrel Size"] = newRecord["Barrel Size"];
      data["barrel_status_id"] = newRecord["barrel_status_id"];
      data["Barrel Date"] = newRecord["Barrel Date"];
      data["Amount Used"] = newRecord["Amount Used"];
      data["item_order_number"] = newRecord["item_order_number"];
      data["Alcohol %"] = newRecord["Alcohol %"];
      data["Notes"] = newRecord["Notes"];

      data["previous_barrel_number"] = "";
      data["finished_product_id"] = newRecord["finished_product_id"];
      data["finished_product_number"] = newRecord["finished_product_number"];
      //if changed manually then Warehouse_ID
      data["warehouse_id"] =
        newRecord["warehouse_id"] || newRecord["Warehouse_ID"];

      //self.dataTable.isInserting = false;
      console.log("saveNewRecordCallback.data", data);

      if (this.mode === this.$constants.FORM_MODE.VIEW && this.actionId) {
        this.$api
          .put(`barrel-filling`, { items: [data] })
          .then(response => {
            self.$form.makeToastInfo(response.message);

            //do not relaod table if there multiple inserting records
            if (!self.$refs.dataTable.isInsertingMode) self.getData();
          })
          .catch(function(error) {
            self.$form.makeToastError(error.message);

            self.getData();
          });
      }

      return true;
    },

    async revertNewRecordCallback() {
      // this.dataTable.isInserting = false;

      return true;
    },
    async deleteItem(row) {
      //let row = this.dataTable.dataSet.find(item => item.uid === row.uid);

      let confirm = await this.$form.showConfirmation(
        `Item will be removed. Do you want to proceed?`
      );

      if (!confirm) return;
      /*
      let idProp =
        this.mode === this.$constants.FORM_MODE.CREATE ? "uid" : "ID";
*/
      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i["uid"] !== row.uid
      );

      /*
      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        self.$api
          .delete(`mash/item/${row.ID}`)
          .then(() => {
            self.$form.makeToastInfo("Item deleted");
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(response.data.message);

            self.getData();
          });
      }
     */
    },
    async getData(payload) {
      this.dataTable.isLoading = true;

      let url = `barrel-filling/${this.actionId}/items`;

      let response = await this.$api.post(url, payload);

      this.rawData = response;

      this.dataTable.isLoading = false;

      this.dataTable.dataSet = this.rawData;

      if (this.$refs.dataTable) this.$refs.dataTable.setOrder("Barrel #", true);
    }
  }
};
</script>

<style scoped></style>
